import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      noIndex,
      title,
      treeParent,
      bannerImage,
      bannerText,
      bannerDarkOverlayOpacity,
      modularBlocks,
    },
  },
}) => (
  <Layout seo={seoMetaTags} noIndex={noIndex}>
    <main>
      <Banner
        heading={title}
        image={bannerImage}
        text={bannerText}
        breadcrumbs={treeParent}
        darkOverlayOpacity={bannerDarkOverlayOpacity}
      />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      title
      treeParent {
        title
        ...LinkFragment
        treeParent {
          title
          ...LinkFragment
          treeParent {
            title
            ...LinkFragment
          }
        }
      }
      bannerImage {
        ...SubPageBannerImageFragment
      }
      bannerText
      bannerDarkOverlayOpacity
      modularBlocks {
        ...AccordionModularBlockFragment
        ...CompetitionFormModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentBlocksModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedProductCategoriesModularBlockFragment
        ...FeaturedRecipesModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HeadingContentModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageCtaModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...ProductCategoriesModularBlockFragment
        ...QuoteModularBlockFragment
        ...StatementTextModularBlockFragment
        ...TabsModularBlockFragment
        ...TextBlocksModularBlockFragment
        ...TextCtaModularBlockFragment
      }
    }
  }
`;

export default DefaultPageTemplate;
